import React from 'react';

import {space} from '@workday/canvas-kit-react/tokens';
import {Flex} from '@workday/canvas-kit-react/layout';
import {Graphic} from '@workday/canvas-kit-react/icon';
import {emptyStateCloudGrayGraphic} from '@workday/canvas-graphics-web';
import {ContentBody} from '../components/content/ContentBody';
import SEO from '../components/SEO';
import {H2} from '../components/StyledElements';
import {FAQNoticeCard} from '../components/content/NoticeCard';

const NotFoundPage = () => (
  <ContentBody>
    <SEO title="404: Not Found" />

    <Flex gap="m" alignItems="center" flexDirection="column">
      <Graphic src={emptyStateCloudGrayGraphic} />
      <H2 css={{marginBottom: space.xl, maxWidth: 480, textAlign: 'center'}}>
        Oops, the page you were looking for cannot be found.
      </H2>
    </Flex>
    <FAQNoticeCard />
  </ContentBody>
);

export default NotFoundPage;
